<template>
  <div class="homePage">
    <DefaultLandingPage/>
  </div>
</template>

<style lang="scss">
  .homePage{
    // overflow:hidden;
  }
  .breakerContainer{
    position: relative;
  }
  .someText{
    height:0px;
    width:100vw;
    display:flex;
    justify-content: center;
    align-items: center;
    p{
      font-size: 32px;
      width:900px;
      text-align: center;
    }
  }
@media screen and (max-width: 1080px) {
  .someText{
    width:70vw;
    height:30vh;
    margin: 0 auto;

    p{
      font-size: 13px;
    }
  }
}
</style>

<script>
import DefaultLandingPage from "../components/DefaultLandingPage/DefaultLandingPage.vue";

export default {
  name: "HomePage",
  components: {
    DefaultLandingPage
},
};
</script>
