<template>
    <div class="fullScreen" v-if="!isMobile">
        <div @click="onClick('header')" class="ctaButton" :style="{width}">
            <svg v-if="!isMobile" xmlns="http://www.w3.org/2000/svg" width="30.817" height="30.701"
                viewBox="0 0 30.817 30.701">
                <path id="Path_4029" data-name="Path 4029"
                    d="M11.321,13.966l2.214-3.043.146-.233.064-.124-.512-.879-.457-.856L12.48,8.1l-.006-.02,4.457,3.242.206.15.01-.023L20,13.542l.042-.017h0l2.685-1.072L19.466,16.9,17.24,19.93l.017.041h0l1.078,2.7,0,0h0L13.889,19.41l-.206-.151-.007.016-2.832-2.06-.042.017h0L8.171,18.3ZM11.129,0l.345,4.6L8.9.724,7.073,12.041l-.018.071-4.3,5.907L.043,21.745l.012,0,0,.005.018,0-.07.1.013,0,0,.005,11.175,1.8.726.119h.006L19.429,30.7l-.031-.175.074.068-.789-4.459.2.244-.092-.271,3.1,3.77L23.737,18.7l.007-.009,4.343-5.924,2.73-3.716-.012,0,0,0L19.042,7.105v0l-.011,0,0-.005-.176-.028Z"
                    fill="#fff" />
            </svg>
            {{text}}
            <svg v-if="!isMobile" id="Group_2156" data-name="Group 2156" xmlns="http://www.w3.org/2000/svg"
                width="21.33" height="20.55" viewBox="0 0 21.33 20.55">
                <path id="_54aec5deb03266394c2fc8af8ec2f2d0" data-name="54aec5deb03266394c2fc8af8ec2f2d0"
                    d="M10.74,1l9.59,10.275L10.74,21.55,8,18.81l6.85-7.535L8,3.74Z" transform="translate(-8 -1)"
                    fill="#fff" />
                <path id="_54aec5deb03266394c2fc8af8ec2f2d0-2" data-name="54aec5deb03266394c2fc8af8ec2f2d0"
                    d="M10.74,1l9.59,10.275L10.74,21.55,8,18.81l6.85-7.535L8,3.74Z" transform="translate(1 -1)"
                    fill="#fff" />
            </svg>
        </div>
    </div>
    <div class="mobile" v-else>
        <div @click="onClick('header')" class="mobileCta" id="mobileCta">
            <svg :style="{minWidth:'10px !important'}" xmlns="http://www.w3.org/2000/svg" width="30.817" height="30.701"
                viewBox="0 0 30.817 30.701">
                <path id="Path_4029" data-name="Path 4029"
                    d="M11.321,13.966l2.214-3.043.146-.233.064-.124-.512-.879-.457-.856L12.48,8.1l-.006-.02,4.457,3.242.206.15.01-.023L20,13.542l.042-.017h0l2.685-1.072L19.466,16.9,17.24,19.93l.017.041h0l1.078,2.7,0,0h0L13.889,19.41l-.206-.151-.007.016-2.832-2.06-.042.017h0L8.171,18.3ZM11.129,0l.345,4.6L8.9.724,7.073,12.041l-.018.071-4.3,5.907L.043,21.745l.012,0,0,.005.018,0-.07.1.013,0,0,.005,11.175,1.8.726.119h.006L19.429,30.7l-.031-.175.074.068-.789-4.459.2.244-.092-.271,3.1,3.77L23.737,18.7l.007-.009,4.343-5.924,2.73-3.716-.012,0,0,0L19.042,7.105v0l-.011,0,0-.005-.176-.028Z"
                    fill="#fff" />
            </svg>
            <p :style="isMain ? {fontSize:'28px !important'}:{}">{{text}}</p>
            <svg id="Group_2156" data-name="Group 2156" xmlns="http://www.w3.org/2000/svg"
                width="21.33" height="20.55" viewBox="0 0 21.33 20.55">
                <path id="_54aec5deb03266394c2fc8af8ec2f2d0" data-name="54aec5deb03266394c2fc8af8ec2f2d0"
                    d="M10.74,1l9.59,10.275L10.74,21.55,8,18.81l6.85-7.535L8,3.74Z" transform="translate(-8 -1)"
                    fill="#fff" />
                <path id="_54aec5deb03266394c2fc8af8ec2f2d0-2" data-name="54aec5deb03266394c2fc8af8ec2f2d0"
                    d="M10.74,1l9.59,10.275L10.74,21.55,8,18.81l6.85-7.535L8,3.74Z" transform="translate(1 -1)"
                    fill="#fff" />
            </svg>
        </div>
    </div>
</template>
  
<style lang="scss" scoped>
.ctaButton:hover {
    transform: scale(1.1);
}
.mobile{
    width:100%;
}
.mobileCta{
    max-width: 300px;
    height: 51px;
    background-color: #D43842;
    border: 1px solid #fff;
    box-shadow: 6px 6px 12px #D43842;
    border-radius: 30px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:0px 10px;
    font-size: 3vw;
    svg{
        width:5vw;
    }
    p{
        min-width:max-content;
    }
    transform: translateX(3vw);
}

.fullScreen {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.ctaButton {
    width: 60%;
    height: 51px;
    background-color: #D43842;
    border: 1px solid #fff;
    box-shadow: 6px 6px 12px #D43842;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 20px;
    font-size: 1.5vw;
    font-weight: 300;
    cursor: pointer;
    user-select: none;
    transition: all 0.5s ease-in-out;
}

@media screen and (max-width: 1080px) {
    .ctaButton {
        font-size: 2.5vw;
    }
}
</style>
<script>
export default {
    name: "CallToAction",
    props: ["text", "onClick", "width", "isMobile","isMain"],
};
</script>
  