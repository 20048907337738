<template>
  <div class="kudoslab">
    <h1 class="sr-only">Kudoslab</h1>
    <main>
      <router-view></router-view>
    </main>
  </div>
</template>

<script>

export default {
  components: {
  },
  mounted(){
    parent.postMessage("cookies", "*");
  }
};
</script>
