<template>
    <div class="footer" v-if="!isMobile">
        <div class="topFooter">
            <a target="_blank" href="https://discord.gg/kudos-rewards" class="link">
                <img src="./images/discord-icon.svg" alt=""> Join our Discord
            </a>
            <div class="kudos">
                <img src="./images/kudos-footer.png" alt="">
            </div>
            <a target="_blank" class="link" href="https://chrome.google.com/webstore/detail/kudos/jjajmdhefncjolocalcgjjfpamniebep">
                <img src="./images/chrome-icon.svg" alt=""> Install our Extension
            </a>
        </div>
        <div id="links" class="bottomFooter">
            <a href="#links" @click="onClick()">About Us</a>
            <a href="#links" @click="onClick()">Careers</a>
            <a href="#links" @click="onClick()">Privacy Policy</a>
            <a href="#links" @click="onClick()">Terms of Service</a>
            <a href="#links" @click="onClick()">Imprint</a>
        </div>
    </div>
    <div class="mobileFooter" v-else>
        <div class="kudos">
            <img src="./images/kudos-footer.png" alt="">
        </div>
        <div class="links">
            <div class="link">
                <img src="./images/chrome-icon.svg" alt=""> Install our Extension
            </div>
            <div class="link">
                <img src="./images/discord-icon.svg" alt=""> Join our Discord
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .bottomFooter{
        display:flex;
        width:50%;
        justify-content: space-between;
        a{
            text-decoration: none;
            color:#fff;
            transition: all 0.5s ease-in-out;
        }
        a:hover{
            transform: scale(1.1);
            color:#D43842;
        }
    }
    .topFooter{
        display:flex;
        justify-content: space-evenly;
        width:100%;
        margin-bottom: 60px;
    }
    .link{
        text-decoration: none;
        color:#fff;
        transition: all 0.5s ease-in-out;
    }
    .link:hover{
        transform: scale(1.1);
    }
    .mobileFooter{
        .kudos{
            width:170px;
            img{
                width:100%;
                height:auto;
                margin-bottom: 45px;
            }
        }
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height:218px;
        background: linear-gradient(0deg, rgba(212, 56, 66, 0.3) 0%, rgba(17,19,21,1) 59%);;
        .links{
            display:flex;
            justify-content: center;
            .link{
            cursor: pointer;
            display:flex;
            justify-content: center;
            width:40vw;
            align-items: center;
            font-size: 10px;
            white-space: nowrap;
            img{
                width:30px;
                height:auto;
                margin-right: 20px;;
            }
        }
        }
    }
    .footer{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgb(212,56,66);
        background: linear-gradient(0deg, rgba(212,56,66,0.3) 0%, rgba(17,19,21,1) 59%);
        height:300px;
        width:100vw;
        align-items: center;
        .link{
            cursor: pointer;
            display:flex;
            justify-content: flex-start;
            width:230px;
            white-space: nowrap;
            align-items: center;
            img{
                width:50px;
                height:auto;
                margin-right: 20px;;
            }
        }
        .kudos{
            width:310px;
            height:65px;
            display:flex;
            img{
                width:100%;
                height:auto;
            }
        }
    }
</style>

<script>
export default {
    name: "Footer",
    data(){
        return {
        }
    },
    methods:{
        onClick() {
            parent.postMessage(`body`, "*");
        },
    },
    computed:{
        isMobile(){
            return window.innerWidth <= 1080
        }
    }
};
</script>
  