<template>
    <div class="homePage">
      <MainPage/>
      <HowItWorks/>
      <LaptopPage/>
      <div class="breakerContainer">
        <Breaker/>
      </div>
      <!-- <BlogPosts/> -->
      <div class="headerButton">
        <HeaderButton html="<b>Read All About It</b>" />
      </div>
      <KudosStripePage/>
      <Footer/>
    </div>
  </template>
  
  <style lang="scss">
    .headerButton{
      width:100%;
      display:flex;
      justify-content: center;
      margin-top: 770px;;
      margin-right: 20px;
    }
    .breakerContainer{
      position: relative;
    }
    .someText{
      width:100vw;
      display:flex;
      justify-content: center;
      align-items: center;
      p{
        font-size: 32px;
        width:900px;
        text-align: center;
      }
    }
  @media screen and (min-width:601px) and (max-width: 1080px) {
    .headerButton{
      margin-top: 55vw;
    }
  }
  @media screen and (max-width: 600px) {
    .headerButton{
      margin-top: 350px;
    }
  }
  </style>
  
  <script>
  import MainPage from "./MainPage.vue";
  import LaptopPage from "./LaptopPage.vue";
  import Breaker from "./components/Breaker.vue";
  import KudosStripePage from "./KudosStripePage.vue";
  import Footer from "./Footer.vue";
  import HowItWorks from "./HowItWorks.vue";
  import HeaderButton from "./components/HeaderButton.vue";
  
  export default {
    name: "DefaultLandingPage",
    components: {
    MainPage,
    LaptopPage,
    Breaker,
    KudosStripePage,
    Footer,
    HowItWorks,
    HeaderButton
},
  };
  </script>
  