<template>
    <div class="howItWorksPage">
        <HeaderButton :style="isMobile ? {} : {marginRight:'65px'}" html="<b>HOW it WORKS</b>" />
        <div id="scroller" class="scroller-container">
            <div class="scroller">
                <div class="cardContainer">
                    <img v-if="openItem !== 'one'" src="./images/icons/card-1.png" alt="" @click="openItem = 'one'">
                    <div v-else class="openCard">
                        <div class="openImage"></div>
                        <div class="openText">Having our integrations installed will help to rack up your Kudos easily Without you having to worry about claiming it.</div>
                        <div class="openButton">Thanks Kudos, this helped</div>
                    </div>
                    <div class="text">Add our extensions with a few clicks or shop directly on our platform to earn
                        Kudos. <br /> <b> It’s 100% free.</b></div>
                </div>
                <div class="cardContainer">
                    <div class="text">We’ll <b>automatically</b> top up your balance with Kudos when you shop on our
                        partners.</div>
                    <img v-if="openItem !== 'two'" src="./images/icons/card-2.png" alt="" @click="openItem = 'two'">
                    <div v-else class="openCard">
                        <div class="openImage"></div>
                        <div class="openText">Browse your favourite shops And convert your cash into Kudos By shopping with our partners</div>
                        <div class="openButton">Thanks Kudos, this helped</div>
                    </div>
                </div>
                <div class="cardContainer">
                    <img v-if="openItem !== 'three'" src="./images/icons/card03.png" alt="" @click="openItem = 'three'">
                    <div v-else class="openCard" :style="{borderColor:'#D43842', boxShadow:'0px 3px 6px #D43842'}">
                        <div class="openImage"></div>
                        <div class="openText">Browse your favourite shops And convert your cash into Kudos By shopping with our partners</div>
                        <div class="openButton" :style="{boxShadow:'6px 6px 12px #D43842', backgroundColor:'#D43842'}">Thanks Kudos, this helped</div>
                    </div>
                    <div class="text">Use your Kudos to redeem our <b>mind-blowing rewards</b>. Dream no more! 🤯</div>
                </div>
            </div>
            <div class="steps">
                <div ref="one" @click="scroll('one')" class="step one active"> Join for Free</div>
                <div ref="two" @click="scroll('two')" class="step two">Shop Like Before</div>
                <div ref="three" @click="scroll('three')" class="step three">Enjoy Jaw-Dropping Rewards</div>
            </div>
        </div>

    </div>
</template>

<style lang="scss" scoped>
.scroller-container::-webkit-scrollbar {
    display: none;
}
.openCard{
    height:550px;
    width:386px;
    border-radius: 20px;
    border: 3px solid #FC9D0F;
    box-shadow: 0px 3px 6px #FC9D0F;
    margin-top: 76px;
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .openImage{
        width:342px;
        height:192px;
        background-color: #fff;
    }
    .openText{
        width:280px;
        font-weight: 500;
        font-size: 13px;
    }
    .openButton{
        width:298px;
        height:34px;
        display:flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 500;
        border-radius: 36px;
        box-shadow: 6px 6px 12px #FC9D0F;
        background-color: #FC9D0F;
        color:#111315;
    }
}
.scroller-container {
    margin: 120px 0;
    display: flex;
    height: 500px;
    width: 100%;
    overflow: scroll;
    position: sticky;
    top: 0;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .scroller {
        width: 50%;
    }

    .steps {
        top: 0;
        left: 50%;
        width: 50%;
        height: 100%;
        position: sticky;
        justify-content: space-evenly;
        display: flex;
        flex-direction: column;

        .step {
            font-family: 'Montserrat', sans-serif;
            width: 60%;
            height: 62px;
            border-radius: 31px;
            border: 3px solid #FC9D0F;
            box-shadow: 3px 3px 6px #FC9D0F;
            background-color: #fff;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            font-size: 28px;
            font-weight: 400;
            color: #FC9D0F;
            flex-direction: column;
            min-width: 400px;
            cursor: pointer;
            user-select: none;
            text-align: center;
            transition: all 0.3s ease-in-out;
        }
        .step:hover{
            box-shadow: 6px 6px 12px #FC9D0F;
        }

        .active {
            background-color: #FC9D0F !important;
            border: 3px solid #fff !important;
            color: #fff;
        }

        .one {
            margin-left: 50px;
        }

        .two {
            margin-left: 100px;
        }

        .three {
            margin-left: 150px;
        }
    }

    .cardContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 500px;

        img {
            width: 50%;
            height: auto;
        }

        .text {
            font-size: 28px;
            width: 50%;
            text-align: center;
        }
    }
}

.howItWorksPage {
    position: relative;
    padding: 120px 100px 0px 150px;
    height: fit-content;
    width: 100vw;
    display: flex;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 1080px) {
    .openCard{
        height:50vw !important;
        width:40vw !important;
        padding:20px 0;
    }
    .openText{
        width:80% !important;
        font-size: 9px !important;
        overflow:hidden;
    }
    .openButton{
        font-size: 9px !important;
        width:80% !important;
        height:10% !important;
    }
    .openImage{
        width:90% !important;
        height:50% !important;
    }
    .howItWorksPage {
        padding: 200px 0 0;
    }

    .steps {
        display: none !important;
    }
    .scroller-container{
        margin-top:10px;
        height:fit-content;
        overflow-y: hidden;

    }
    .scroller{
    }
    .cardContainer{
        height:350px !important;
        .text{
            font-size: 22px !important;
            padding: 0 10px;;
        }
    }
    .scroller {
        width: 100% !important;
    }
}
</style>

<script>
import HeaderButton from "./components/HeaderButton.vue";
export default {
    name: "HowItWorksPage",
    data() {
        return {
            openItem:null,
            pageYOffset:null
        };
    },
    methods: {
        elementInViewport(el) {
            var top = el.offsetTop;
            var left = el.offsetLeft;
            var width = el.offsetWidth;
            var height = el.offsetHeight;

            while (el.offsetParent) {
                el = el.offsetParent;
                top += el.offsetTop;
                left += el.offsetLeft;
            }

            return (
                top >= window.pageYOffset &&
                left >= window.pageXOffset &&
                (top + height) <= (window.pageYOffset + window.innerHeight) &&
                (left + width) <= (window.pageXOffset + window.innerWidth)
            );
        },
        scroll(item) {
            const scroller = document.getElementById("scroller");
            if (item === "one") {
                scroller.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
            }
            if (item === "two") {
                scroller.scrollTo({
                    top: 490,
                    behavior: "smooth"
                });
            }
            if (item === "three") {
                scroller.scrollTo({
                    top: 1000   ,
                    behavior: "smooth"
                });
            }
        }
    },
    mounted() {
        document.getElementById("scroller").addEventListener("scroll", e => {
            if (e.target.scrollTop < 340) {
                this.$refs.two.classList.remove("active");
                this.$refs.one.classList.add("active");
            }
            if (e.target.scrollTop >= 340 && e.target.scrollTop < 780) {
                this.$refs.one.classList.remove("active");
                this.$refs.three.classList.remove("active");
                this.$refs.two.classList.add("active");
            }
            if (e.target.scrollTop >= 780) {
                this.$refs.two.classList.remove("active");
                this.$refs.three.classList.add("active");
            }
        });
    },
    components: { HeaderButton },
    computed:{
        isMobile(){
            return window.innerWidth <= 1080
        }
    }
};
</script>
  