import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: Home,
    meta: {
      title: 'Home'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  window.document.title = `Kudoslab ${to.meta.title}` || 'Kudoslab'
  next()
})

export default router
