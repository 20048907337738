<template>
    <div ref="kudosStripePage" class="kudosStripePage">
        <div ref="scrollingKudos" class="scrollingKudos"></div>
        <div class="blogCardContainer">
            <BlogCard v-for="post in posts" :post="post" v-bind:key="post.name"/>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .blogCardContainer{
        display:flex;
        flex-wrap: wrap;
        align-items: center;
        width:100%;
        justify-content: space-evenly;
    }
    .scrollingKudos{
        position: absolute;
        top:31vh;
        left:0;
        width:130%;
        z-index: -2;
    }
    .kudosStripePage{
        margin-top: 120px;
        position: relative;
        width:100vw;
        min-height: 80vh;
        height:fit-content;
        display:flex;
        justify-content: center;
        align-items: center;
        overflow:hidden;
    }
    .laptopRedBg{
        width:1000px;
        height:1000px;
        background-image: url("./images/laptop-red-bg.png");
        background-position: center;
        background-size: cover;
    }
    @media screen and (max-width: 1080px) {
        .laptopRedBg{
            width:100vw;
            height:100vw;
        }
        .kudosStripePage{
            min-height:80vh;
            height:fit-content;
            margin-top: 0px;;
        }
        .scrollingKudos{
            position: absolute;
            top:46.5%;
            left:0;
            width:250%;
            z-index: -1;
        }
    }
</style>

<script>
import * as lottie from "lottie-web";
import BlogCard from "./components/BlogCard.vue";
export default {
    name: "KudosStripePage",
    data() {
        return {
            posts:[]
        };
    },
    async mounted() {
        const response = await this.axios.get("https://api.kudoslabs.gg/content/page")
        const data = response.data.data
        data.filter(it=>it.landingPage).forEach(it=>this.posts.push(it))
        const animationData = require("./images/kudosLogo.json");
        lottie.loadAnimation({
            container: this.$refs.scrollingKudos,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData
        });
        if (window.innerWidth > 1080) {
            document.addEventListener("scroll", e => {
                const scrollTop = e.target.documentElement.scrollTop;
                const refs = this.$refs;
                const height = refs.kudosStripePage.getBoundingClientRect().height;
                const top = refs.kudosStripePage.getBoundingClientRect().top;
                if (top < 0) {
                    if (height * 0.2 > top * -1) {
                        refs.laptopRedBg.style.transform = `scale(${scrollTop * 0.0003})`;
                    }
                }
            });
        }
    },
    components: { BlogCard }
};
</script>
  