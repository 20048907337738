<template>
    <div class="laptopPage">
        <div ref="cardLeft" class="cardLeft">
            <img src="./images/laptop-card-left.png" alt="" srcset=""/>
        </div>
        <div ref="cardRight" class="cardRight">
            <img src="./images/laptop-card-right.png" alt="" srcset=""/>
        </div>
        <div ref="background" class="background">
            <div ref="table" class="table">
                <div ref="laptop" class="laptop">
                    <div class="textBubble">
                        <div class="arrowDown"></div>
                        <p>All the rewards you</p>
                        <div class="typewriter">
                            <h1>need, want & got to have</h1>
                        </div>
                    </div>
                    <div ref="smoke" class="smoke"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @keyframes typing {
    0% { width: 0 }
    10% {width:4ch}
    20% {width:4ch}
    30% {width:9ch}
    40% {width:9ch}
    60% {width:100%}
    100% { width: 100% }
    }
    .textBubble{
            position: absolute;
            width:35vw;
            height:fit-content;
            right:15vw;
            top:100px;
            background-color: #fff;
            border-radius: 20px;;
            color:#000;
            display:flex;
            justify-content: flex-start;
            align-items: center;
            padding:1.5vw 19px;
            .arrowDown{
                position: absolute;
                width: 0; 
                height: 0; 
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-top: 20px solid #fff;
                bottom:-19px;
                left:150px;
            }
            p{
                white-space: nowrap;
                font-size: 1.3vw;
                display:flex;
                align-items: center;
            }
            .typewriter{
                margin-left: 17px;
                h1{
                    overflow: hidden;
                    white-space: nowrap;
                    text-align: left;
                    display:flex;
                    justify-content: flex-start;
                    font-size: 1.3vw;
                    animation: typing 3.5s steps(30, end) infinite
                    }
            }
        }
    .laptopPage{
        position: relative;
        width:100vw;
        height:70vw;
        background-color: #111315;
        .cardLeft{
            transform: translate(-0.0666667px) rotate3d(1, 1, 1, 66.5333deg);
            position: absolute;
            left:20vw;
            top:0px;
            z-index: 0;
            width:15vw;
            img{
                width:100%;
                height:auto;
            }
        }
        .cardRight{
            position: absolute;
            right:20vw;
            bottom:50px;
            width:15vw;
            img{
                width:100%;
                height:auto;
            }
        }
        .background{
            background-image: url("./images/Neon-Circle.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position-x: center;
            background-position-y: 80%;
            height:100%;
            z-index: 2;
            .table{
                width:100%;
                height:100%;
                background-image: url("./images/table.png");
                background-size: contain;
                background-repeat: no-repeat;
                background-position-x: center;
                background-position-y: 80%;
                .laptop{
                    width:100%;
                    height:100%;
                    background-image: url("./images/laptop.png");
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position-x: center;
                    background-position-y: 80%;
                    .smoke{
                        width:100vw;
                        height:100%;
                        background-image: url("./images/smoke.png");
                        background-size: 3000px;
                        background-repeat: no-repeat;
                        background-position-x: 100%;
                        background-position-y: 80%;
                    }
                }
            }
            img{
                width:100vw;
                height:auto;
            }
        }
    }
    @media screen and (max-width: 1080px) {
        .textBubble{
                max-width:100vw;
                height:52px;
                top:20vw;
                right:2vw;
                width:380px;
                z-index: 1;
                p{
                    font-size: 14px;
                }
                .typewriter{
                    h1{
                        font-size: 14px;
                    }
                }
                .arrowDown{
                    left:unset;
                    right:20vw;
                }
            }
        .laptopPage{
            height:140vw;
            width:auto;
            position: relative;
            .cardLeft{
                width:35vw;
                height:auto;
                left:5vw;
                top:10vw;
            }
            .cardRight{
                width:35vw;
                height:auto;
                right:-5vw;
                bottom:15vw;
            }
            .background{
                background-size: 200%;
                background-position-y: 0%;
                .table{
                    background-size: 200%;;
                    background-position-y: -20%;
                    .laptop{
                        background-size: 200%;;
                        background-position-y: -20%;
                        .smoke{
                            background-size: 400%;
                            background-position-y: 80%;
                        }
                    }
                }
            }
        }
        
    }
</style>

<script>
export default {
    name: "LaptopPage",
    data(){
        return {
        }
    },
    mounted(){
        document.addEventListener('scroll', e=>{
            const scrollTop = e.target.documentElement.scrollTop
            const refs = this.$refs;
            refs.cardRight.style.transform = `translate(-${(scrollTop -500) / 30}px) rotate(${(scrollTop-1500)/90}deg)`
            refs.cardLeft.style.transform = `translate(-${(scrollTop -500) / 30}px) rotate3d(1,1,1,${(scrollTop-1500)/30}deg)`
        })
        if(window.innerWidth > 1080){
        document.addEventListener('scroll', e=>{
            const scrollTop = e.target.documentElement.scrollTop
            const refs = this.$refs;
            // refs.background.style.backgroundSize = `calc(80vw + ${scrollTop*0.1}px)`
            refs.background.style.backgroundPositionY = `-${(scrollTop - 3500) * 0.1}px`
            refs.laptop.style.backgroundPositionY = `${(scrollTop - 2000) / 30}px`
            refs.table.style.backgroundPositionY = `${(scrollTop - 1000) / 15}px`
            refs.smoke.style.backgroundPositionY = `-${(scrollTop * 0.02 + 35)}vw`
            refs.smoke.style.backgroundSize = `${200 + scrollTop*0.01}%`
        })
       }
    }
};
</script>
  