<template>
    <div class="blogCard">
        <div class="blogCardImage" :style="{backgroundImage:`url(${post && post['big image'] && post['big image'][0] ? post['big image'][0].thumbnail_large : ''})`}">

        </div>
        <div ref="blogCardTitle" class="blogCardTitle">
            {{post.name}}
        </div>
        <div class="blogCardText">
            {{post.text.replaceAll("///","")}}
        </div>
        <div class="blogCardCTA" @click="onClick()">
            {{post.buttontext || "Read more"}}
            <svg id="Group_2157" data-name="Group 2157" xmlns="http://www.w3.org/2000/svg" width="15.648" height="15.076" viewBox="0 0 15.648 15.076">
                <path id="_54aec5deb03266394c2fc8af8ec2f2d0" data-name="54aec5deb03266394c2fc8af8ec2f2d0" d="M10.01,1l7.035,7.538L10.01,16.076,8,14.066l5.025-5.528L8,3.01Z" transform="translate(-8 -1)" fill="#d43842"/>
                <path id="_54aec5deb03266394c2fc8af8ec2f2d0-2" data-name="54aec5deb03266394c2fc8af8ec2f2d0" d="M10.01,1l7.035,7.538L10.01,16.076,8,14.066l5.025-5.528L8,3.01Z" transform="translate(-1.397 -1)" fill="#d43842"/>
            </svg>

        </div>
    </div>
</template>
  
  <style lang="scss" scoped>
    .blogCard{
        z-index: 2;
        background-color: #111315;
        width:480px;
        // height:670px;
        border-radius: 20px;;
        margin-bottom: 50px;;
        box-shadow: 0px 3px 6px #fff;
        .blogCardImage{
            width:100%;
            height:340px;
            border-radius: 20px;;
            margin-bottom: 27px;;
            border:3px solid #fff;
            background-size: cover;
            background-position: center;
        }
        .blogCardTitle{
            height:58px;
            font-size: 48px;
            font-weight: bold;
            margin-bottom: 22px;
            margin-left: 34px;;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            display: -webkit-box;
            overflow:hidden;
            svg{
                width:100%;
                height:100%;
            }
        }
        .blogCardText{
            margin-bottom: 32px;;
            margin-left: 34px;
            margin-right: 33px;;
            height:130px;
            font-size: 13px;
            -webkit-line-clamp: 8;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            display: -webkit-box;
            overflow:hidden;
        }
        .blogCardCTA:hover{
            transform: scale(1.1);
            background-color: #D43842;
            color:#fff;
        }
        .blogCardCTA{
            user-select: none;;
            cursor: pointer;
            min-width:257px;
            width:fit-content;
            padding:0 35px;;
            height:41px;
            font-size: 21px;
            font-weight: 600;
            display:flex;
            justify-content: center;
            align-items: center;
            position: relative;
            margin:0 auto 29px;
            background: #fff;
            color:#D43842;
            border-radius: 40px;;
            transition: all 0.5s ease-in-out;
            svg{
                position: absolute;
                top:13px;
                right:15px;
            }
        }
    }
@media screen and (max-width: 1080px) {
    .blogCard{
        transform:scale(0.9)
    }
}
  </style>
  <script>
export default {
    name: "BlogCard",
    props:["post"],
    mounted(){
        this.$refs.blogCardTitle.style.fontSize = this.post.name.length > 15 ? "25px" : "48px"
    },
    methods:{
        onClick() {
            parent.postMessage(`body`, "*");
        },
        goTo(url){
            window.open(url,"_blank")
        }
    }
};
</script>
  