<template>
    <div class="breaker">
        <div class="left side">
        </div>
        <div class="mid">
            <b>Sounds too good to be true?</b>
            <p id="smallText">Shop at our partners to get spectacular rewards!</p>
            <nuxt-link to="/shops" @click="() => spawnBubbles = false">
                <CallToAction text="SIGN UP" :onClick="onClick" :isMobile="isMobile" :width="isMobile ? '150px' : '301px'"/>
            </nuxt-link>
        </div>
        <div class="right side">
        </div>
    </div>
</template>
  
  <style lang="scss">
  @keyframes raise {
      0% {
          top: 500px
      }
  
      100% {
          top: -200px
      }
  }
  
  .breaker {
      z-index: 998;
      position: absolute;
      left: 5vw;
      top: -5vh;
      width: 90vw;
      height: 700px;
      display: flex;
      background-color: #111315;
      border-radius: 40px;
      box-shadow: 6px 6px 12px #000;
  
      .side {
          min-width: 20%;
          height: 100%;
          position: relative;
          z-index: -1;
      }
  
      .mid {
          font-size: 1.8vw;
          width: 60%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          height: 100%;
          .mobile{
            .mobileCta{
                transform: none;;
            }
          }
  
          a {
              text-decoration: none;
              color: #fff;
          }
          b{
            font-size: 49px;
            font-weight: bold;
          }
          #smallText{
            font-size: 32px;
            font-weight: lighter;
            margin-bottom: 40px;;
          }
  
          .shoppingTime {
              margin-top: 30%;
              width: 9.5vw;
              height: 2.5vw;
              font-size: 1vw;
              background-color: #D5485D;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 36px;
              user-select: none;;
              cursor: pointer;
              transition:all .2s ease-in-out;
          }
          .shoppingTime:hover{
            transform: scale(1.1);
          }
      }
  }
  
  @media screen and (max-width: 1080px) {
    
      .breaker {
          height: 50vw;
          min-height:350px;
  
          .mid {
              font-size: 3vw;
              b{
                font-size: 5vw;
              }
              #smallText{
                font-size: 4vw;
              }
              a :first-child{
                font-size: 2vw !important;;
              }
              
  
              .shoppingTime {
                  width: 30vw;
                  height: 7vw;
                  font-size: 4vw;
              }
          }
      }
  }
  </style>
  <script>
import CallToAction from './CallToAction.vue';
export default {
    name: "Breaker",
    data() {
        return {
            spawnBubbles: true,
            allShopBubbles: {
                INT: [
                    { name: "BandaiNamco", image: "https://kudos-content-images-prod.s3.eu-central-1.amazonaws.com/shop-logo-review-29072022-2/BANDAI+NAMCO.png" },
                    { name: "edX", image: "https://kudos-content-images-prod.s3.eu-central-1.amazonaws.com/shop-logo-review-29072022-2/edx.png" },
                    { name: "Eneba", image: "https://kudos-content-images-prod.s3.eu-central-1.amazonaws.com/shops/eneba.png" },
                    { name: "EpicGames", image: "https://kudos-content-images-prod.s3.eu-central-1.amazonaws.com/shop-logo-review-29072022-2/epic.png" },
                    { name: "Fanatical", image: "https://kudos-content-images-prod.s3.eu-central-1.amazonaws.com/shop-logo-review-29072022-2/fanatical.png" },
                    { name: "GoG", image: "https://kudos-content-images-prod.s3.eu-central-1.amazonaws.com/shops-2/GoG_brand.jpg" },
                    { name: "HumbleBundle", image: "https://kudos-content-images-prod.s3.eu-central-1.amazonaws.com/shops/humble_bundle_coming_soon.png" },
                    { name: "IvacyVPN", image: "https://kudos-content-images-prod.s3.eu-central-1.amazonaws.com/shop-logo-review-29072022-2/ivacy+vpn.png" },
                    { name: "Kinguin", image: "https://kudos-content-images-prod.s3.eu-central-1.amazonaws.com/shops/kinguin.png" },
                    { name: "NordVPN", image: "https://kudos-content-images-prod.s3.eu-central-1.amazonaws.com/shops/nordvpn.png" },
                    { name: "Spotify", image: "https://kudos-content-images-prod.s3.eu-central-1.amazonaws.com/shop-logo-review-29072022-2/spotify.png" },
                    { name: "Steam", image: "https://kudos-content-images-prod.s3.eu-central-1.amazonaws.com/shops-2/Steam.jpg" },
                    { name: "Xtrfy", image: "https://kudos-content-images-prod.s3.eu-central-1.amazonaws.com/shops/xtrfy.png" },
                ],
            },
            shopBubbles: {},
        };
    },
    async mounted() {
        this.shopBubbles = [...this.getRegionalShops];
        while (this.spawnBubbles) {
            await this.spawnAllShopBubbles();
        }
    },
    computed: {
        getRegionalShops() {
            return [...this.allShopBubbles["INT"]];
        },
        isMobile() {
            return window.innerWidth <= 1080;
        }
    },
    methods: {
        onClick(type) {
            if (type === "header" || type === "body") {
                parent.postMessage(`${type}`, "*");
            }
            if (type === "discord") {
                parent.postMessage("discordClick", "*");
            }
            if (type === "extension") {
                parent.postMessage("extensionClick", "*");
            }
        },
        async spawnAllShopBubbles() {
            this.spawnShopBubble(Math.random() * 10 + 100, "left");
            this.spawnShopBubble(Math.random() * 10 + 100, "right");
            await new Promise(r => setTimeout(r, 400));
            this.spawnShopBubble(100 - Math.random() * 10, "left");
            this.spawnShopBubble(100 - Math.random() * 10, "right");
            await new Promise(r => setTimeout(r, 400));
            this.spawnShopBubble(Math.random() * 20 + 100, "left");
            this.spawnShopBubble(Math.random() * 20 + 100, "right");
            await new Promise(r => setTimeout(r, 400));
            this.spawnShopBubble(Math.random() * 20 + 100, "left");
            this.spawnShopBubble(Math.random() * 20 + 100, "right");
            await new Promise(r => setTimeout(r, 400));
            this.spawnShopBubble(100 - Math.random() * 10, "left");
            this.spawnShopBubble(100 - Math.random() * 10, "right");
            await new Promise(r => setTimeout(r, 1000));
        },
        spawnShopBubble(start, position) {
            const shopElement = document.createElement("div");
            const shopStyle = shopElement.style;
            let container = document.querySelector(position === "left" ? ".left" : ".right");
            if (!container) {
                this.spawnBubbles = false;
                return;
            }
            container.appendChild(shopElement);
            if (position === "left") {
                shopStyle.left = `calc(30vw - ${Math.round(container.offsetWidth / 100 * start + Math.random() * (container.offsetWidth / 2)) + "px"})`;
            }
            else {
                shopStyle.right = `calc(30vw - ${Math.round(container.offsetWidth / 100 * start + Math.random() * (container.offsetWidth / 2)) + "px"})`;
            }
            const shopIndex = Math.floor(Math.random() * this.shopBubbles.length);
            shopStyle.bottom = "0px";
            shopStyle.width = `calc(${this.isMobile ? 4 : 3}vw * 1.43)`;
            shopStyle.height = `calc(${this.isMobile ? 4 : 3}vw)`;
            shopStyle.animationName = "raise";
            shopStyle.position = "absolute";
            shopStyle.animationDuration = "4s";
            shopStyle.animationDelay = "0x";
            shopStyle.animationIterationCount = "infinite";
            shopStyle.borderRadius = container.offsetWidth / 25 + "px";
            shopStyle.backgroundImage = `url(${this.shopBubbles[shopIndex].image})`;
            shopStyle.backgroundColor = "#fff";
            shopStyle.backgroundPosition = "center";
            shopStyle.backgroundSize = "cover";
            shopStyle.backgroundRepeat = "no-repeat";
            const maxSize = Math.round(Math.random() * 10);
            const chance = Math.random() * 50
            let randomizer = -1;
            if(chance > 25){
                randomizer = chance * -2
            }
            if(chance > 50){
                randomizer = chance * 2
            }
            shopElement.animate([
                { top: container.offsetHeight - container.offsetHeight / 10 + "px", transform: "scale(0.3)" },
                { top: container.offsetHeight - container.offsetHeight / 2 - randomizer  + "px", transform: `scale(1.${maxSize})` },
                { top: container.offsetHeight - container.offsetHeight / 2 - randomizer - container.offsetHeight / 10 + "px", transform: `scale(2.0${maxSize})` },
                { top: "0px", transform: "scale(0)" },
            ], {
                offset: [0, 0.1, 0.8, 1],
                duration: 2000,
                iterations: Infinity
            });
            this.shopBubbles.splice(shopIndex, 1);
            if (this.shopBubbles.length < 2) {
                this.shopBubbles = [...this.getRegionalShops];
            }
            setTimeout(() => {
                shopElement.remove();
            }, 1900);
            return shopElement;
        },
    },
    components: { CallToAction }
};
</script>
  