<template>
    <div class="landingHeader">
        <div class="leftSide">
            <img src="./images/left-logo.png" alt="">
        </div>
        <div class="middle">
            <div class="links">
                <a target="_blank" @click="onClick('discord')" href="https://discord.gg/kudos-rewards"
                    class="link discord">
                    <img src="./images/discord-icon.svg" alt=""> Join our Discord
                </a>
                <a v-if="!isMobile" target="_blank" @click="onClick('extension')"
                    href="https://chrome.google.com/webstore/detail/kudos/jjajmdhefncjolocalcgjjfpamniebep"
                    class="link chrome">
                    <img src="./images/chrome-icon.svg" alt=""> Install our Extension
                </a>
            </div>
        </div>
        <div class="rightSide">
            <CallToAction id="ctaButton" :isMobile="isMobile" text="SIGN UP" :onClick="onClick" width="50%"/>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.landingHeader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #111315;
    height: 132px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;

    .leftSide {
        width: 33%;
        display:flex;
        padding-left: 57px;;
        justify-content: flex-start;
        align-items: center;
        img{
            width:50%;
            height:auto;
        }
    }

    .middle {
        width: 33%;
        .links{
            display:flex;
            justify-content: space-between;
            align-items: center;
            color:#fff;
            a{
                img{
                    margin:0 20px;;
                }
                color:#fff;
                text-decoration: none;
                display:flex;
                justify-content: center;
                align-items: center;
                transition: all 0.5s ease-in-out;
            }
            a:hover{
                transform: scale(1.1);
            }
        }
    }

    .rightSide {
        width: 33%;
        display:flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 41px;;
    }
}

@media screen and (max-width: 1080px) {
    #ctaButton{
        min-width: 130px !important;;
        justify-content: center !important;
        transform: translateX(25px) scale(0.8);
        svg{
            display:none !important;
        }
    }
    .leftSide{
        padding-left:20px !important;
        margin:0;
        img{
            width:150% !important;
        }
    }
    .middle{
        .links{
            margin-left: 100px;;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            a{
                width:200px;
                transform: scale(
                    0.7
                );
            }
            a:hover{
                transform: scale(0.7) !important;
            }
        }
    }
    }
</style>

<script>
import CallToAction from './components/CallToAction.vue';
export default {
    name: "Footer",
    data() {
        return {};
    },
    methods:{
        onClick(type) {
            if (type === "header" || type === "body") {
                parent.postMessage(`${type}`, "*");
            }
            if (type === "discord") {
                parent.postMessage("discordClick", "*");
            }
            if (type === "extension") {
                parent.postMessage("extensionClick", "*");
            }
        },
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 1080;
        }
    },
    components: { CallToAction }
};
</script>
  