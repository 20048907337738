<template>
    <div class="mainPage">
        <DefaultLandingHeader />
        <div v-if="!isMobile" class="icons">
            <div class="icon" v-for="item in icons" :ref="item.name" :key="item.name" :style="{width:`${item.size}px`, height:`${item.size}px`, top:`${item.top}%`, left:`${item.left}%`, right:`${item.right}%`}">
                <img :src="getIcon(item)" alt="">
            </div>
        </div>
        <div v-if="isMobile" class="icons">
            <div class="icon" :class="item.name" v-for="item in icons" :ref="item.name" :key="item.name" :style="{width:`${item.size}px`, height:`${item.size}px`, top:`${item.top}%`, left:`${item.name === 'amazon' ? 0 : item.left/10}%`, right:`${item.right/2}%`}">
                <img :src="getIcon(item)" alt="">
            </div>
        </div>
        <div class="middleContainer">
            <h1>#1 platform for getting world-class gaming rewards</h1>
            <svg xmlns="http://www.w3.org/2000/svg" width="296.387" height="27.725" viewBox="0 0 296.387 27.725">
            <g id="Group_2167" data-name="Group 2167" transform="translate(-660.244 -360.403)">
                <text id="FOR_GAMERS" data-name="FOR GAMERS" transform="translate(660.244 378.403)" fill="#fff" font-size="17" font-family="SegoeUI, Segoe UI" letter-spacing="0.03em"><tspan x="0" y="0">FOR GAMERS</tspan></text>
                <text id="BY_GAMERS" data-name="BY GAMERS" transform="translate(862.632 379.338)" fill="#fff" font-size="17" font-family="SegoeUI, Segoe UI" letter-spacing="0.03em"><tspan x="0" y="0">BY GAMERS</tspan></text>
                <path id="f3659b9415e36298376566beae762998" d="M23.2,7.176a6.62,6.62,0,0,0-6.183,9.916l.66,1.143,6.594,11.455,11.435-6.62,1.145-.661a6.609,6.609,0,1,0-6.6-11.455l-1.143.661-.659-1.145A6.573,6.573,0,0,0,23.2,7.176Zm-8.427,7.471a5.731,5.731,0,0,0-2.534,2.335l-.577,1-1-.581A5.778,5.778,0,1,0,4.892,27.418l1,.579L15.9,33.786l4.551-7.9-4-6.945L15.793,17.8a7.981,7.981,0,0,1-1.018-3.152Z" transform="translate(802.879 354.342)" fill="#d43842" fill-rule="evenodd"/>
            </g>
            </svg>
            <p>It's time for you to get something back — shop now on any of our <b>+500 shops</b> to earn Kudos!</p>
            <div class="cta">
                <CallToAction  :isMain="true" text="SIGN UP" :isMobile="isMobile" :onClick="onClick" width="300px"/>
                <div class="smallText">
                    <div class="spacer"></div>
                    <p>It's free after all</p>
                </div>
            </div>
            <div class="more">
                <div class="container">
                    
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="66.146" height="66.146" viewBox="0 0 66.146 66.146">
                    <defs>
                        <filter id="Path_4026" x="0" y="0" width="66.146" height="66.146" filterUnits="userSpaceOnUse">
                        <feOffset dy="3" input="SourceAlpha"/>
                        <feGaussianBlur stdDeviation="3" result="blur"/>
                        <feFlood flood-opacity="0.161"/>
                        <feComposite operator="in" in2="blur"/>
                        <feComposite in="SourceGraphic"/>
                        </filter>
                    </defs>
                    <g id="Group_2169" data-name="Group 2169" transform="translate(-919 -964)">
                        <g transform="matrix(1, 0, 0, 1, 919, 964)" filter="url(#Path_4026)">
                        <path id="Path_4026-2" data-name="Path 4026" d="M24.073,0A24.073,24.073,0,1,1,0,24.073,24.073,24.073,0,0,1,24.073,0Z" transform="translate(9 6)" fill="#fff"/>
                        </g>
                        <g id="Group_2168" data-name="Group 2168" transform="translate(959.803 986.05) rotate(90)">
                        <path id="_54aec5deb03266394c2fc8af8ec2f2d0" data-name="54aec5deb03266394c2fc8af8ec2f2d0" d="M2.061,0,9.276,7.73,2.061,15.46,0,13.4,5.153,7.73,0,2.061Z" fill="#d43842"/>
                        <path id="_54aec5deb03266394c2fc8af8ec2f2d0-2" data-name="54aec5deb03266394c2fc8af8ec2f2d0" d="M2.061,0,9.276,7.73,2.061,15.46,0,13.4,5.153,7.73,0,2.061Z" transform="translate(6.771)" fill="#d43842"/>
                        </g>
                    </g>
                </svg>
                <p>But wait, there's more</p>

                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;800;900&display=swap');
    @keyframes nudge{
        0%{
            transform: translateY(0px);
        }
        10%{
            transform: translateY(10px);
        }
        20%{
            transform:  translateY(5px);
        }
        30%{
            transform: translateY(10px);
        }
        40%{
            transform: translateY(0px);
        }
        100%{
            transform: translateY(0px);
        }
    }
    @keyframes slideFromBot{
        0%{
            transform:translateY(200px) scale(0)
        }
        100%{
            transform: translateY(0px) scale(1)
        }
    }
    .pc{
        right:0 !important;
    }
    .more{
        animation: slideFromBot 0.5s ease 0.5s 1 normal forwards;    
        transform: scale(0);        
        display:flex;
        justify-content: center;
        width:100%;
        flex-direction: column;
        align-items: center;
        position: absolute;
        left:0;
        bottom: -50px;
        .container{
            svg{
            margin-bottom: 0px !important;
            animation-name: nudge !important;
            animation-duration: 3s !important;
            animation-iteration-count: infinite !important;
            animation-timing-function: ease-in-out !important;
        }
        p{
            font-weight: normal !important;
            font-size: 16px !important;
        }
        }
    }
    .smallText{
        display:flex;
        margin-top: 10px;
        .spacer{
            width:50%;
        }
        p{
            font-size: 16px !important;
            font-weight: normal !important;
        }
    }
    .icon{
        position: absolute;
        img{
            width:100%;
            height:100%;
        }
    }
@keyframes scaleInBack {
    0% {
        transform: scale(2);
    }

    100% {
        transform: scale(1) rotate3d(0, 1, 0, 41deg);
    }
}

@keyframes scaleInFwd {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes floating {
        0% { transform: translate(0,  0px); }
        50%  { transform: translate(0, 15px); }
        100%   { transform: translate(0, -0px); }   
    }
@keyframes floating-mobile {
    0% { transform: translate(0,  0px), scale(0.5) }
    50%  { transform: translate(0, 15px), scale(0.5); }
    100%   { transform: translate(0, -0px), scale(0.5); }   
}

.floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.getMeIn {
    max-width: 295px;
    height: 47px;
    width: 80vw;
    background: #fff;
    border-radius: 40px;
    color: #D43842;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    margin-top: 27px;
    cursor: pointer;
    user-select: none;
    transition: all .2s ease-in-out;
}

.getMeIn:hover {
    transform: scale(1.1);
}

.mainPage {
    padding-top: 132px;;
    width: 100vw;
    height: 100vh;
    background-image: url('./images/main-page-bg.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .textContainer {
        animation: scaleInFwd 0.5s ease 0.5s 1 normal forwards;
        transform: scale(0);
        width: 600px;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 37px;

        .topText {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .bottomText {
            width: 100%;
            display: flex;
            justify-content: space-between;

            p {
                text-transform: uppercase;
            }
        }
    }

    .middleContainer {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width:38%;
        text-align: center;
        h1{
            font-weight: bold;
            font-size: 52px;
            margin-bottom: 12px;
            transform: scale(0);
            animation: scaleInFwd 0.5s ease 0.5s 1 normal forwards;      
        }
        svg{
            margin-bottom: 40px;
            transform: scale(0);
            animation: scaleInFwd 0.5s ease 0.75s 1 normal forwards;
        }
        p{
            font-size: 21px;
            letter-spacing: 0.63px;
            margin-bottom: 71px;
            font-weight: 400;
            transform: scale(0);
            animation: scaleInFwd 0.5s ease 1s 1 normal forwards;
        }
        .cta{
            width:300px;
            transform: scale(0);
            animation: scaleInFwd 0.5s ease 1.25s 1 normal forwards;
        }

        .logo {
            width: 600px;
            height: 130px;
            background-position: center;
            background-size: cover;
            transform: rotate3d(0, 1, 0, 40deg);
        }

    }
}

.link {
    text-decoration: none;
    color: #fff
}

@media screen and (max-width: 1080px) {
    .loginButton {
        display: none;
    }

    .links {
        width: 100vw;
        justify-content: space-evenly;

        .link {
            display: flex;
            justify-content: center;
            font-size: 10px;

            img {
                width: 30px;
                height: auto
            }
        }
    }

    .middleContainer {
        transform: scale(0.6);
    }

    .mainPage {
        height: 100vh;
        overflow: hidden;
        font-family: 'Montserrat', sans-serif !important;
    }
}
@media screen and (max-width: 1080px) {
        .icon{
            right: 0 !important;
            width:100px !important;
            height:100px !important;
        }
        .middleContainer{
            width: 100% !important;
        }
        .more{
            bottom:-300px;
        }
    }
</style>

<script>
import DefaultLandingHeader from './DefaultLandingHeader.vue';
import CallToAction from './components/CallToAction.vue';
export default {
    name: "MainPage",
    data() {
        return {
            icons:[
                {
                    name:"gfuel",
                    top:16,
                    left:20,
                    size:107
                },
                {
                    name:"amazon",
                    top:28,
                    left:6,
                    size:190
                },
                {
                    name:"nike",
                    top:61,
                    left:14.5,
                    size:145
                },
                {
                    name:"logitech",
                    top:90,
                    left:9,
                    size:139
                },
                {
                    name:"pc",
                    top:16,
                    right:10,
                    size:203
                },
                {
                    name:"mouse",
                    top:62,
                    right:12,
                    size:170
                },
                {
                    name:"keyboard",
                    top:92,
                    right:6,
                    size:150
                },
                {
                    name:"vbuks",
                    top:44,
                    right:4,
                    size:150
                },
            ]
        };
    },
    methods: {
        onClick(type) {
            if (type === "header" || type === "body") {
                parent.postMessage(`${type}`, "*");
            }
            if (type === "discord") {
                parent.postMessage("discordClick", "*");
            }
            if (type === "extension") {
                parent.postMessage("extensionClick", "*");
            }
        },
        getIcon(item){
            return require(`./images/icons/${item.name}.png`)
        }
    },
    computed: {
        isMobile() {
            return window.innerWidth <= 1080;
        }
    },
    mounted() {
        document.querySelectorAll(".icon").forEach(icon=>{
            setTimeout(() => {
                icon.classList.add("floating")
            }, Math.random()*2000);
        })
    },
    components: { DefaultLandingHeader, CallToAction }
};
</script>
  