<template>
    <div class="howItWorksButton" v-html="html"></div>
</template>
  
  <style lang="scss" scoped>
    .howItWorksButton{
            width:440px;
            height:106px;
            border: 1px solid #FC9D0F;
            box-shadow: 6px 6px 12px #FC9D0F;
            border-radius: 10px;
            display:flex;
            justify-content: center;
            align-items: center;
            font-size: 26px;
            b{
                margin-right: 5px;
            }
        }
    @media screen and (max-width: 1080px) {
        .howItWorksButton{
            width:80%;
            margin-bottom: 50px;
        }
    }

  </style>
  <script>
export default {
    name: "HeaderButton",
    props:["html"],
};
</script>
  